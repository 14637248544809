import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Footer from './Footer';
import Header from './Header';
import Gezondheid from './Gezondheid';
import Welzijn from './Welzijn';
import Duurzaamheid from './Duurzaamheid';
import ActiefBurgerschap from './ActiefBurgerschap';
import SocialeBetrokkenheid from './SociaaleBetrokkenheid';

function App() {
  return (
    <Router>
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <Header />
      
      {/* Main content */}
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gezondheid" element={<Gezondheid />} />
          <Route path="/welzijn" element={<Welzijn />} />
          <Route path="/duurzaamheid" element={<Duurzaamheid />} />
          <Route path="/actief-burgerschap" element={<ActiefBurgerschap />} />
          <Route path="/sociale-betrokkenheid" element={<SocialeBetrokkenheid />} />
        </Routes>
      </main>
      
      {/* Footer */}
      <Footer />
    </div>
  </Router>
  );
}

export default App;

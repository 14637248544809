import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const SocialeBetrokkenheid = () => {
  const heroRef = useRef(null);
  const heroInView = useInView(heroRef, { triggerOnce: true, threshold: 0.2 });

  const uitlegRef = useRef(null);
  const uitlegInView = useInView(uitlegRef, { triggerOnce: true, threshold: 0.2 });

  const betrokkenheidVerbeterenRef = useRef(null);
  const betrokkenheidVerbeterenInView = useInView(betrokkenheidVerbeterenRef, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="">
      {/* Hero Section */}
      <motion.section
        ref={heroRef}
        className="relative bg-cover bg-center h-[50vh] mb-12"
        style={{ backgroundImage: 'url(/sociale.jpg)' }}
        initial={{ opacity: 0 }}
        animate={heroInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: .3 }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-6xl font-bold mb-4">Sociale Betrokkenheid</h1>
        </div>
      </motion.section>

      {/* Uitleg over Sociale Betrokkenheid */}
      <motion.section
        ref={uitlegRef}
        className="container mx-auto px-6 py-12 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={uitlegInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Wat is Sociale Betrokkenheid?</h2>
            <p className="text-gray-600 leading-relaxed mb-6">
              Sociale betrokkenheid verwijst naar de mate waarin individuen zich actief inzetten voor hun sociale omgeving. 
              Dit kan door het onderhouden van relaties, deelnemen aan gemeenschappelijke activiteiten, en bijdragen aan 
              het versterken van de onderlinge solidariteit. Sociale betrokkenheid creëert een gevoel van verbondenheid, 
              wat leidt tot een hechtere en veerkrachtigere gemeenschap. Het is belangrijk om zowel in moeilijke tijden 
              als in tijden van voorspoed betrokken te blijven bij de mensen om ons heen.
            </p>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/sociale.jpg"
              alt="Sociale Betrokkenheid"
              className=" object-cover object-center w-full h-80"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={uitlegInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>
      </motion.section>

      {/* Hoe kunnen we Sociale Betrokkenheid bevorderen? */}
      <motion.section
        ref={betrokkenheidVerbeterenRef}
        className="container mx-auto px-6 py-12 mb-60"
        initial={{ opacity: 0, y: 50 }}
        animate={betrokkenheidVerbeterenInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <h2 className="text-3xl font-bold text-gray-800 mb-12 text-center">Hoe kunnen we Sociale Betrokkenheid bevorderen?</h2>

        {/* Individuele Rol in Sociale Betrokkenheid */}
        <div className="md:flex md:items md:justify-between mb-52">
          <div className="md:w-1/2">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">De Rol van het Individu</h3>
            <p className="text-gray-600 leading-relaxed mb-6">
              Individuen spelen een sleutelrol in het bevorderen van sociale betrokkenheid. 
              Dit begint vaak met eenvoudige acties zoals het versterken van relaties met vrienden, familie en buren.
              Door actief te luisteren, empathie te tonen, en tijd te investeren in anderen, 
              kunnen we een cultuur van wederzijds respect en zorg bevorderen. 
              Sociale betrokkenheid vereist ook dat we ons bewust blijven van de behoeften van kwetsbare groepen in onze samenleving,
              zoals ouderen of mensen met een beperking, en hen ondersteunen waar mogelijk.
            </p>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/persooon.jpg"
              alt="Individuele Sociale Acties"
              className="w-full"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={betrokkenheidVerbeterenInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>

        {/* Samenwerking in de Gemeenschap */}
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2 md:pr-12">
            <motion.img
              src="/samenwerking.jpg"
              alt="Samenwerking Gemeenschap"
              className=" w-full"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={betrokkenheidVerbeterenInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
          <div className="md:w-1/2">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">Samenwerking binnen de Gemeenschap</h3>
            <p className="text-gray-600 leading-relaxed mb-6">
              Sociale betrokkenheid gaat verder dan individuele acties; het draait ook om collectieve inspanningen binnen de gemeenschap.
              Door samenwerking tussen bewoners te bevorderen, zoals het gezamenlijk organiseren van evenementen, buurtprojecten of 
              ondersteuningsnetwerken, creëren we een sterk sociaal vangnet. Deze initiatieven dragen bij aan een gevoel van veiligheid,
              verantwoordelijkheid en saamhorigheid. Hoe meer mensen zich actief inzetten voor hun gemeenschap, hoe sterker en duurzamer de band binnen de samenleving wordt.
            </p>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default SocialeBetrokkenheid;

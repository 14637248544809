import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeader = () => {
    if (window.scrollY > lastScrollY) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlHeader);
    return () => {
      window.removeEventListener('scroll', controlHeader);
    };
  }, [lastScrollY]);

  return (
    <header className={`bg-white p-4 text-black shadow-lg fixed top-0 left-0 w-full z-10 transition-transform duration-300 ${scrollDirection === 'down' ? '-translate-y-full' : 'translate-y-0'}`}>
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-3xl font-bold">
          <Link to="/">Vitaal Burgerschap</Link>
        </h1>
        <nav>
          <ul className="flex space-x-10">
            <li>
              <Link to="/gezondheid" className="hover:text-gray-400">Gezondheid</Link>
            </li>
            <li>
              <Link to="/welzijn" className="hover:text-gray-400">Welzijn</Link>
            </li>
            <li>
              <Link to="/duurzaamheid" className="hover:text-gray-400">Duurzaamheid</Link>
            </li>
            <li>
              <Link to="/actief-burgerschap" className="hover:text-gray-400">Actief Burgerschap</Link>
            </li>
            <li>
              <Link to="/sociale-betrokkenheid" className="hover:text-gray-400">Sociale Betrokkenheid</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useInView } from 'framer-motion';

const Welzijn = () => {
  const heroRef = useRef(null);
  const heroInView = useInView(heroRef, { triggerOnce: true, threshold: 0.2 });

  const uitlegRef = useRef(null);
  const uitlegInView = useInView(uitlegRef, { triggerOnce: true, threshold: 0.2 });

  const gezondheidVerbeterenRef = useRef(null);
  const gezondheidVerbeterenInView = useInView(gezondheidVerbeterenRef, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="">
      <motion.section
        ref={heroRef}
        className="relative bg-cover bg-center h-[50vh] mb-12"
        style={{ backgroundImage: 'url(/welzijn.jpg)' }}
        initial={{ opacity: 0 }}
        animate={heroInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: .3 }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-6xl font-bold mb-4">Welzijn</h1>
        </div>
      </motion.section>

      <motion.section
        ref={uitlegRef}
        className="container mx-auto px-6 py-12 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={uitlegInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Welzijn en Vitaal Burgerschap</h2>
            <p className="text-gray-600 leading-relaxed mb-6">
            Welzijn verwijst naar de algemene staat van welbevinden van een individu,
             waarbij zowel fysieke, mentale als sociale aspecten een rol spelen. 
             Het gaat verder dan alleen de afwezigheid van ziekte, 
             het omvat ook een gevoel van geluk, tevredenheid en voldoening in het leven.
              Mensen met een hoog welzijnsniveau hebben de mentale veerkracht om 
              uitdagingen aan te gaan, onderhouden gezonde relaties, en ervaren een 
              evenwicht tussen de verschillende aspecten van hun leven. Het mentale en 
              sociale welzijn zijn net zo belangrijk als het fysieke welzijn: een goede 
              balans tussen deze drie pijlers is essentieel voor een vitaal en gelukkig leven.
            </p>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/welzz.jpg"
              alt="Vitaal Burgerschap"
              className=" object-cover object-center w-full h-80"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={uitlegInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>
      </motion.section>

      {/* Hoe kunnen we gezondheid verbeteren */}
      <motion.section
        ref={gezondheidVerbeterenRef}
        className="container mx-auto px-6 py-12 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={gezondheidVerbeterenInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <h2 className="text-3xl font-bold text-gray-800 mb-12 text-center">Hoe kunnen we Welzijn verbeteren?</h2>

        {/* Fysieke Gezondheid Verbeteren */}
        <div className="md:flex md:items md:justify-between mb-12">
          <div className="md:w-1/2">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">Fysieke Gezondheid Verbeteren</h3>
            <p className="text-gray-600 leading-relaxed mb-6">
            Een goede manier om welzijn te bevorderen is door sociale cohesie en deelname aan de samenleving te stimuleren. 
            Dit kan door actief deel te nemen aan sociale activiteiten, vrijwilligerswerk te doen of 
            regelmatig sociale contacten te onderhouden. Sociale betrokkenheid draagt bij aan zowel 
            mentaal als sociaal welzijn en versterkt het gevoel van verbondenheid en vertrouwen in de 
            gemeenschap. Volgens gegevens van het CBS is 83,4% van de volwassenen in Nederland 
            in 2022 tevreden met hun leven. Vooral mensen met sterke sociale relaties en 
            participatie in de samenleving hebben vaak een hoger welzijn ( zie foto rechts ).
            </p>
            <a href="https://www.cbs.nl/nl-nl/dossier/dossier-brede-welvaart-en-de-sustainable-development-goals/monitor-brede-welvaart-en-de-sustainable-development-goals-2023/verdeling/indicatoren/welzijn" target="_blank" className="text-blue-500 underline">
              Bekijk de statistieken over welvaart op CBS
            </a>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/welzijn-stat.png"
              alt="Bewegen"
              className="  w-full"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={gezondheidVerbeterenInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>
       
      </motion.section>
    </div>
  );
};

export default Welzijn;

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-16">
      <div className="container mx-auto px-6">
        {/* Top section with navigatie links in 4 rows, 2 columns */}
        <div>
          <h2 className="text-2xl font-bold mb-6">Navigatie</h2>
          <ul className="grid grid-cols-2 gap-x-10 gap-y-3">
            <li>
              <Link to="/" className="hover:text-gray-400 transition duration-200">Home</Link>
            </li>
            <li>
              <Link to="/gezondheid" className="hover:text-gray-400 transition duration-200">Gezondheid</Link>
            </li>
            <li>
              <Link to="/welzijn" className="hover:text-gray-400 transition duration-200">Welzijn</Link>
            </li>
            <li>
              <Link to="/duurzaamheid" className="hover:text-gray-400 transition duration-200">Duurzaamheid</Link>
            </li>
            <li>
              <Link to="/actief-burgerschap" className="hover:text-gray-400 transition duration-200">Actief Burgerschap</Link>
            </li>
            <li>
              <Link to="/sociale-betrokkenheid" className="hover:text-gray-400 transition duration-200">Sociale Betrokkenheid</Link>
            </li>
          </ul>
        </div>

        {/* Bottom section with copyright */}
        <div className="border-t border-gray-700 mt-10 pt-6 text-center">
          <p className="text-gray-500 text-sm">
            © {new Date().getFullYear()} Deniz Hrnjic Alle rechten voorbehouden.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

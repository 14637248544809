import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useInView } from 'framer-motion';

const Gezondheid = () => {
  const heroRef = useRef(null);
  const heroInView = useInView(heroRef, { triggerOnce: true, threshold: 0.2 });

  const uitlegRef = useRef(null);
  const uitlegInView = useInView(uitlegRef, { triggerOnce: true, threshold: 0.2 });

  const gezondheidVerbeterenRef = useRef(null);
  const gezondheidVerbeterenInView = useInView(gezondheidVerbeterenRef, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="">
      {/* Hero Section */}
      <motion.section
        ref={heroRef}
        className="relative bg-cover bg-center h-[50vh] mb-12"
        style={{ backgroundImage: 'url(/gezondheid.webp)' }}
        initial={{ opacity: 0 }}
        animate={heroInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: .3 }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-6xl font-bold mb-4">Gezondheid</h1>
        </div>
      </motion.section>

      {/* Uitleg over Vitaal Burgerschap */}
      <motion.section
        ref={uitlegRef}
        className="container mx-auto px-6 py-12 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={uitlegInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Gezondheid en Vitaal Burgerschap</h2>
            <p className="text-gray-600 leading-relaxed mb-6">
              Gezondheid speelt een cruciale rol in vitaal burgerschap, 
              omdat zowel fysieke als mentale gezondheid de basis vormen voor actief en betrokken leven in de samenleving. 
              Wanneer mensen fysiek gezond zijn, hebben ze de energie en veerkracht om deel te nemen aan sociale,
              culturele en economische activiteiten, wat hun bijdrage aan de gemeenschap vergroot.
              Mentale gezondheid is net zo belangrijk: een stabiele mentale toestand stelt mensen in staat 
              om verbindingen aan te gaan met anderen, empathie te tonen en actief deel te nemen aan 
              het verbeteren van hun omgeving.
            </p>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/health.jpg"
              alt="Vitaal Burgerschap"
              className=" object-cover object-center w-full h-80"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={uitlegInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>
      </motion.section>

      {/* Hoe kunnen we gezondheid verbeteren */}
      <motion.section
        ref={gezondheidVerbeterenRef}
        className="container mx-auto px-6 py-12 mb-60"
        initial={{ opacity: 0, y: 50 }}
        animate={gezondheidVerbeterenInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <h2 className="text-3xl font-bold text-gray-800 mb-12 text-center">Hoe kunnen we gezondheid verbeteren?</h2>

        {/* Fysieke Gezondheid Verbeteren */}
        <div className="md:flex md:items md:justify-between mb-52">
          <div className="md:w-1/2">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">Fysieke Gezondheid Verbeteren</h3>
            <p className="text-gray-600 leading-relaxed mb-6">
            Fysieke gezondheid kan worden verbeterd door regelmatig te bewegen en een gebalanceerd
             dieet te volgen. Volgens de CBS-statistieken voldeed in 2022 slechts 44% van de 
             Nederlanders van 18 jaar en ouder aan de beweegrichtlijnen (zie foto rechts ), wat een daling is ten
              opzichte van eerdere jaren. Dit betekent dat meer mensen actiever zouden moeten
               worden om ten minste 2,5 uur per week matig intensieve inspanning te leveren,
                zoals fietsen of wandelen, om hun fysieke gezondheid te verbeteren. Regelmatig
                 bewegen helpt niet alleen bij het behouden van een gezond gewicht, 
            maar verbetert ook de algehele vitaliteit en draagt bij aan een actiever burgerschap.
            </p>
            <a href="https://longreads.cbs.nl/nederland-in-cijfers-2023/hoeveel-volwassenen-voldoen-aan-de-beweegrichtlijnen/" target="_blank" className="text-blue-500 underline">
              Bekijk de statistieken over fysieke activiteit op CBS
            </a>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/stat-bewegingsrichtlijnen.png"
              alt="Bewegen"
              className="   w-full"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={gezondheidVerbeterenInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>

        {/* Mentale Gezondheid Verbeteren */}
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2 md:pr-12">
            <motion.img
              src="/stat-burnout.png"
              alt="Mentale Gezondheid"
              className=" w-full "
              initial={{ scale: 0.8, opacity: 0 }}
              animate={gezondheidVerbeterenInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
          <div className="md:w-1/2">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">Mentale Gezondheid Verbeteren</h3>
            <p className="text-gray-600 leading-relaxed mb-6">
            Mentale gezondheid kan worden bevorderd door stress te verminderen en sterke sociale relaties op te bouwen.
             Volgens de laatste cijfers van het RIVM heeft in 2023 18,7% van de werknemers tussen de 15 
             en 74 jaar last van burn-outklachten ( zie foto links ). Deze toename benadrukt het belang van actief 
             stressmanagement, zoals het gebruik van mindfulness, beweging en sociale ondersteuning,
              om een gezonde mentale balans te behouden. Mentale gezondheid is cruciaal voor vitaal
               burgerschap, omdat het mensen in staat stelt actief deel te nemen aan hun gemeenschap
                en veerkrachtiger om te gaan met de uitdagingen van het leven.
            </p>
            <a href="https://www.rivm.nl/mentale-gezondheid/monitor/werkenden/burn-out-klachten#:~:text=van%20interactieve%20grafiek.-,Het%20percentage%20werknemers%20(15%20t%2Fm%2074%20jaar)%20met,%2C7%25%20(2023)." target="_blank" className="text-blue-500 underline">
              Bekijk de statistieken over burn-out op CBS
            </a>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default Gezondheid;

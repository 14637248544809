import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useInView } from 'framer-motion';

const Duurzaamheid = () => {
  const heroRef = useRef(null);
  const heroInView = useInView(heroRef, { triggerOnce: true, threshold: 0.2 });

  const uitlegRef = useRef(null);
  const uitlegInView = useInView(uitlegRef, { triggerOnce: true, threshold: 0.2 });

  const duurzaamheidVerbeterenRef = useRef(null);
  const duurzaamheidVerbeterenInView = useInView(duurzaamheidVerbeterenRef, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="min-h-screen ">
      {/* Hero Section */}
      <motion.section
        ref={heroRef}
        className="relative bg-cover bg-center h-[50vh] mb-12"
        style={{ backgroundImage: 'url(/duurzaamheid.webp)' }}
        initial={{ opacity: 0 }}
        animate={heroInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: .3 }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-6xl font-bold mb-4">Duurzaamheid</h1>
        </div>
      </motion.section>

      <motion.section
        ref={uitlegRef}
        className="container mx-auto px-6 py-12 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={uitlegInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Duurzaamheid en Vitaal Burgerschap</h2>
            <p className="text-gray-600 leading-relaxed mb-6">
              Duurzaamheid in het kader van vitaal burgerschap gaat over het maken van keuzes die 
              het welzijn van huidige en toekomstige generaties waarborgen. Dit betekent verantwoord omgaan 
              met natuurlijke hulpbronnen, vermindering van afval en emissies, en investeren in sociale 
              gelijkheid. Duurzaam handelen is niet alleen een verantwoordelijkheid van overheden of bedrijven, 
              maar ook van burgers die bewuste keuzes kunnen maken in hun dagelijkse leven, zoals minder energieverbruik, 
              recycling en het steunen van duurzame initiatieven.
            </p>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/kern.jpg"
              alt="Duurzaamheid"
              className="object-cover object-center w-full h-80"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={uitlegInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>
      </motion.section>

      <motion.section
        ref={duurzaamheidVerbeterenRef}
        className="container mx-auto px-6 py-12 mb-60"
        initial={{ opacity: 0, y: 50 }}
        animate={duurzaamheidVerbeterenInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <h2 className="text-3xl font-bold text-gray-800 mb-12 text-center">Hoe kunnen we duurzaamheid verbeteren?</h2>

        <div className="md:flex md:items md:justify-between mb-52">
          <div className="md:w-1/2">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">Concrete stappen naar meer duurzaamheid</h3>
            <p className="text-gray-600 leading-relaxed mb-6">
              Duurzaamheid kan worden bevorderd door individuele keuzes te maken die bijdragen aan een 
              schonere planeet. Volgens een rapport van het Centraal Bureau voor de Statistiek (CBS) nam de 
              CO2-uitstoot per Nederlander in 2022 af met 5,4% ten opzichte van 2021. Dit was grotendeels 
              het gevolg van energiebesparing en de overstap naar duurzame energiebronnen, zoals zonne- 
              en windenergie. Burgers kunnen hun steentje bijdragen door bewuster energie te gebruiken, 
              duurzame producten te kopen en afval te scheiden, wat niet alleen het milieu beschermt maar ook 
              de eigen leefomgeving ten goede komt.
            </p>
            <a
              href="https://www.cbs.nl/nl-nl/nieuws/2024/11/uitstoot-broeikasgassen-6-procent-lager-in-2023"
              target="_blank"
              className="text-blue-500 underline"
            >
              Bekijk de statistieken over CO2-uitstoot op CBS
            </a>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/co2-stat.png"
              alt="CO2 reductie"
              className="   w-full"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={duurzaamheidVerbeterenInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default Duurzaamheid;

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useInView } from 'framer-motion';

const Home = () => {
  const heroRef = useRef(null);
  const heroInView = useInView(heroRef, { triggerOnce: true, threshold: 0.2 });

  const uitlegRef = useRef(null);
  const uitlegInView = useInView(uitlegRef, { triggerOnce: true, threshold: 0.2 });

  const gridSectionRef = useRef(null);
  const gridSectionInView = useInView(gridSectionRef, { triggerOnce: true, threshold: 0.2 });

  const gezondheidRef = useRef(null);
  const gezondheidInView = useInView(gezondheidRef, { triggerOnce: true, threshold: 0.2 });

  const welzijnRef = useRef(null);
  const welzijnInView = useInView(welzijnRef, { triggerOnce: true, threshold: 0.2 });

  const duurzaamheidRef = useRef(null);
  const duurzaamheidInView = useInView(duurzaamheidRef, { triggerOnce: true, threshold: 0.2 });

  const actiefBurgerschapRef = useRef(null);
  const actiefBurgerschapInView = useInView(actiefBurgerschapRef, { triggerOnce: true, threshold: 0.2 });

  const socialeBetrokkenheidRef = useRef(null);
  const socialeBetrokkenheidInView = useInView(socialeBetrokkenheidRef, { triggerOnce: true, threshold: 0.2 });

  const mijnVisieRef = useRef(null);
  const mijnVisieInView = useInView(mijnVisieRef, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <motion.section
        ref={heroRef}
        className="relative bg-cover bg-center h-[50vh] mb-12"
        style={{ backgroundImage: 'url(/hero-bg.jpg)' }}
        initial={{ opacity: 0 }}
        animate={heroInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: .3 }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-6xl font-bold mb-4">Vitaal Burgerschap</h1>
          <p className="text-2xl">Gemaakt door Deniz</p>
        </div>
      </motion.section>

      {/* Uitleg over Vitaal Burgerschap */}
      <motion.section
        ref={uitlegRef}
        className="container mx-auto px-6 py-12 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={uitlegInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Wat is Vitaal Burgerschap?</h2>
            <p className="text-gray-600 leading-relaxed mb-6">
              Vitaal burgerschap draait om het actief bijdragen aan een gezonde, duurzame en betrokken samenleving.
              Het gaat niet alleen om persoonlijke gezondheid, maar ook om de impact die je hebt op je omgeving en de wereld om je heen.
              Iedereen kan een rol spelen in het versterken van zijn of haar eigen vitaliteit en die van anderen door middel van gezondheid, welzijn, en actieve betrokkenheid bij sociale en ecologische kwesties.
            </p>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/burgerschap.png"
              alt="Vitaal Burgerschap"
              className="rounded-lg shadow-lg object-cover object-center w-full h-80"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={uitlegInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>
      </motion.section>

      {/* Titel voor Grid Sectie */}
      <motion.section
        ref={gridSectionRef}
        className="container mx-auto px-6 py-6 text-center "
        initial={{ opacity: 0 }}
        animate={gridSectionInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: .3 }}
      >
        <h2 className="text-4xl font-bold text-gray-800">Onderwerpen op deze website</h2>
        <p className="text-gray-600 text-lg mt-4">
          Verken de verschillende thema's die we behandelen, van gezondheid tot duurzaamheid, en hoe jij een actieve rol kunt spelen.
        </p>
      </motion.section>

      {/* Grid Sectie */}
      <motion.section
        className="container mx-auto px-6 py-8 grid grid-cols-1 md:grid-cols-3 gap-8 mb-16"
      >
        {/* Gezondheid */}
        <motion.div
          ref={gezondheidRef}
          className="bg-white shadow-lg rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={gezondheidInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: .3, delay: 1}}
        >
          <img src="gezondheid.webp" alt="Gezondheid" className="w-full h-48 object-cover" />
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Gezondheid</h2>
            <p className="text-gray-600 mb-4">
              Leer hoe je je fysieke en mentale gezondheid kunt bevorderen voor een vitaler leven.
            </p>
            <Link to="/gezondheid" className="inline-flex items-center text-blue-500 hover:underline">
              <span className="mr-2">Lees meer</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </motion.div>

        {/* Welzijn */}
        <motion.div
          ref={welzijnRef}
          className="bg-white shadow-lg rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={welzijnInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: .3, delay: 1 }}
        >
          <img src="welzijn.jpg" alt="Welzijn" className="w-full h-48 object-cover" />
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Welzijn</h2>
            <p className="text-gray-600 mb-4">
              Ontdek hoe welzijn kan bijdragen aan het verbeteren van je kwaliteit van leven.
            </p>
            <Link to="/welzijn" className="inline-flex items-center text-blue-500 hover:underline">
              <span className="mr-2">Lees meer</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </motion.div>

        {/* Duurzaamheid */}
        <motion.div
          ref={duurzaamheidRef}
          className="bg-white shadow-lg rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={duurzaamheidInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: .3, delay: 1 }}
        >
          <img src="duurzaamheid.webp" alt="Duurzaamheid" className="w-full h-48 object-cover" />
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Duurzaamheid</h2>
            <p className="text-gray-600 mb-4">
              Verken manieren om duurzaam te leven en onze planeet te beschermen voor toekomstige generaties.
            </p>
            <Link to="/duurzaamheid" className="inline-flex items-center text-blue-500 hover:underline">
              <span className="mr-2">Lees meer</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </motion.div>

        {/* Actief Burgerschap */}
        <motion.div
          ref={actiefBurgerschapRef}
          className="bg-white shadow-lg rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={actiefBurgerschapInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: .3, delay: 1 }}
        >
          <img src="actief-burgerschap.jpg" alt="Actief Burgerschap" className="w-full h-48 object-cover" />
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Actief Burgerschap</h2>
            <p className="text-gray-600 mb-4">
              Leer hoe je een actieve rol kunt spelen in je gemeenschap en een positieve impact kunt maken.
            </p>
            <Link to="/actief-burgerschap" className="inline-flex items-center text-blue-500 hover:underline">
              <span className="mr-2">Lees meer</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </motion.div>

        {/* Sociale Betrokkenheid */}
        <motion.div
          ref={socialeBetrokkenheidRef}
          className="bg-white shadow-lg rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={socialeBetrokkenheidInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: .3, delay: 1 }}
        >
          <img src="sociale.jpg" alt="Sociale Betrokkenheid" className="w-full h-48 object-cover" />
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Sociale Betrokkenheid</h2>
            <p className="text-gray-600 mb-4">
              Begrijp het belang van betrokkenheid bij sociale kwesties en het ondersteunen van anderen.
            </p>
            <Link to="/sociale-betrokkenheid" className="inline-flex items-center text-blue-500 hover:underline">
              <span className="mr-2">Lees meer</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </motion.div>

        {/* Mijn Visie */}
        <motion.div
          ref={mijnVisieRef}
          className="bg-white shadow-lg rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={mijnVisieInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: .3, delay: 1 }}
        >
          <img src="visie.jpg" alt="Mijn Visie" className="w-full h-48 object-cover" />
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Mijn Visie</h2>
            <p className="text-gray-600 mb-4">
              Lees mijn persoonlijke visie op vitaal burgerschap en hoe we samen een verschil kunnen maken.
            </p>
            <Link to="/mijn-visie" className="inline-flex items-center text-blue-500 hover:underline">
              <span className="mr-2">Lees meer</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </motion.div>
      </motion.section>
    </div>
  );
};

export default Home;

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useInView } from 'framer-motion';

const ActiefBurgerschap = () => {
  const heroRef = useRef(null);
  const heroInView = useInView(heroRef, { triggerOnce: true, threshold: 0.2 });

  const uitlegRef = useRef(null);
  const uitlegInView = useInView(uitlegRef, { triggerOnce: true, threshold: 0.2 });

  const burgerschapVerbeterenRef = useRef(null);
  const burgerschapVerbeterenInView = useInView(burgerschapVerbeterenRef, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <motion.section
        ref={heroRef}
        className="relative bg-cover bg-center h-[50vh] mb-12"
        style={{ backgroundImage: 'url(/actief-burgerschap.jpg)' }}
        initial={{ opacity: 0 }}
        animate={heroInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: .3 }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-6xl font-bold mb-4">Actief Burgerschap</h1>
        </div>
      </motion.section>

      {/* Uitleg over Actief Burgerschap */}
      <motion.section
        ref={uitlegRef}
        className="container mx-auto px-6 py-12 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={uitlegInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Wat is Actief Burgerschap?</h2>
            <p className="text-gray-600 leading-relaxed mb-6">
              Actief burgerschap verwijst naar de betrokkenheid van individuen bij hun gemeenschap en samenleving,
              waarbij ze verantwoordelijkheid nemen voor het bevorderen van gemeenschappelijke belangen en actief bijdragen aan sociale cohesie.
              Het betekent niet alleen deelnemen aan politieke processen zoals stemmen, maar ook vrijwilligerswerk doen,
              lokale initiatieven ondersteunen en bewust keuzes maken die het welzijn van anderen verbeteren. 
              Een samenleving floreert wanneer burgers zich betrokken voelen en gezamenlijk streven naar een betere toekomst.
            </p>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/burgers.jpg"
              alt="Actief Burgerschap"
              className=" object-cover object-center w-full h-80"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={uitlegInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>
      </motion.section>

      {/* Hoe kunnen we Actief Burgerschap bevorderen? */}
      <motion.section
        ref={burgerschapVerbeterenRef}
        className="container mx-auto px-6 py-12 mb-60"
        initial={{ opacity: 0, y: 50 }}
        animate={burgerschapVerbeterenInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: .3 }}
      >
        <h2 className="text-3xl font-bold text-gray-800 mb-12 text-center">Hoe kunnen we Actief Burgerschap bevorderen?</h2>

        {/* Individuele Rol in Actief Burgerschap */}
        <div className="md:flex md:items md:justify-between mb-52">
          <div className="md:w-1/2">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">De Rol van het Individu</h3>
            <p className="text-gray-600 leading-relaxed mb-6">
              Als individuen kunnen we actief burgerschap bevorderen door te beginnen met onze directe omgeving. 
              Dit kan door betrokken te zijn bij lokale gemeenschapsactiviteiten, zoals buurtbijeenkomsten, vrijwilligerswerk,
              of het ondersteunen van lokale bedrijven. Kleine acties, zoals een handje helpen bij het organiseren van evenementen of 
              deel te nemen aan een buurtnetwerk, dragen bij aan het vergroten van sociale cohesie. Daarnaast kunnen we onszelf 
              blijven informeren over maatschappelijke kwesties en anderen aanmoedigen om ook actief deel te nemen aan het publieke debat.
            </p>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <motion.img
              src="/person.jpeg"
              alt="Individuele Acties"
              className=" w-full"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={burgerschapVerbeterenInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
        </div>

        {/* Samenwerking binnen de Gemeenschap */}
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2 md:pr-12">
            <motion.img
              src="/actief.jpg"
              alt="Gemeenschapsbetrokkenheid"
              className=" w-full"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={burgerschapVerbeterenInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
              transition={{ duration: .3 }}
            />
          </div>
          <div className="md:w-1/2">
            <h3 className="text-2xl font-bold text-gray-700 mb-4">Samenwerking binnen de Gemeenschap</h3>
            <p className="text-gray-600 leading-relaxed mb-6">
              Actief burgerschap is ook afhankelijk van samenwerking binnen de gemeenschap. Gemeenschappen kunnen initiatieven starten
              die burgers in staat stellen om samen te werken aan het verbeteren van hun omgeving, zoals gemeenschappelijke tuinen, buurtwachten,
              of platforms waar mensen ideeën kunnen uitwisselen. Het delen van kennis, middelen en vaardigheden draagt bij aan een sterker
              gevoel van verbondenheid en vertrouwen binnen de samenleving. Door open te staan voor samenwerking, bevorderen we sociale cohesie
              en creëren we een gemeenschap waarin iedereen zich gehoord en betrokken voelt.
            </p>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default ActiefBurgerschap;
